'use strict';

// Hero title parallax
// -----------------------------------------------
if (document.querySelector('.hero')) {
    var hero = document.querySelector('.hero');
    var heroRoot = parallax.create(hero);
    // heroRoot.add(document.querySelector('.hero_title-wrap'), -0.12);
    heroRoot.add(document.querySelector('.hero_title-wrap'), {
        end: {
            y: -70
        }
    });
}

// Image lazyloading
// -----------------------------------------------
var imageLazyLoad = new LazyLoad({
    elements_selector: "img.lazy",
    threshold: 300
});

// Smooth scroll 
// -----------------------------------------------

// Set up smooth scrolling to #anchor links
var scroll = new SmoothScroll('a[href*="#"]', {

    header: 'header', // Selector for fixed headers (must be a valid CSS selector)
    offset: -20,

    // Speed & Easing
    speed: 900, // Integer. How fast to complete the scroll in milliseconds
    easing: 'easeInOutCubic' // Easing pattern to use
});

// Set up a custom event for #anchor links when you don't want to show the link in the browser address bar
// Mobile devices reveal the address bar when clicking anchor links, this shrinks the browser size and makes the animation weirdish.
// Usage: add .anchorless class to the link 
if (document.querySelector('.anchorless')) {

    var achorlessLinks = document.querySelectorAll('.anchorless');

    // Loop over all the anchorless links

    var _loop = function _loop(i) {

        var link = achorlessLinks[i];
        var elToScrollTo = document.querySelector(link.getAttribute('href'));

        // Listen for a click on the anchorless link
        link.addEventListener('click', function (event) {

            // Prevent the # from showing up in the address bar. 
            event.preventDefault();

            // Scroll to the element
            scroll.animateScroll(elToScrollTo);
        });
    };

    for (var i = 0; i < achorlessLinks.length; i++) {
        _loop(i);
    }
}

document.addEventListener("DOMContentLoaded", function () {

    var header = document.querySelector('header');

    // Hide Header on scroll down
    // ----------------------------------------------------
    var didScroll;
    var lastScrollTop = 0;
    var delta = 5;
    var navbarHeight = header.offsetHeight;

    window.addEventListener('scroll', function (event) {
        didScroll = true;
    });

    setInterval(function () {
        if (didScroll) {
            manipulateHeader();
            didScroll = false;
        }
    }, 250);

    function manipulateHeader() {
        var st = window.pageYOffset;

        // Make sure they scroll more than delta
        if (Math.abs(lastScrollTop - st) <= delta) return;

        // Hide and show header based on scroll down and up.
        if (st > lastScrollTop && st > navbarHeight) {
            // Hide header
            header.classList.add('hidden');
        } else {
            // Show header
            header.classList.remove('hidden');
        }

        lastScrollTop = st;
    }

    // Toggle mobile nav
    // ----------------------------------------------------
    if (document.querySelector('.mobile-menu')) {

        (function () {

            var hamburger = document.querySelector('.header_hamburger');
            var closeButton = document.querySelector('.mobile-menu .close-menu');

            hamburger.addEventListener('click', function (event) {

                setTimeout(function () {
                    document.body.classList.toggle('mob-menu-open');
                }, 130);
            });

            closeButton.addEventListener('click', function (event) {
                document.body.classList.toggle('mob-menu-open');
            });
        })();
    }

    // Search toggle
    // ----------------------------------------------------
    if (document.querySelector('.search-menu')) {

        (function () {
            var searchToggle = document.querySelector('.header_search');
            var closeButton = document.querySelector('.search-menu .close-menu');

            searchToggle.addEventListener('click', function (event) {
                document.body.classList.toggle('search-menu-open');
            });

            closeButton.addEventListener('click', function (event) {
                document.body.classList.toggle('search-menu-open');
            });
        })();
    }
});