'use strict';

var appearingElements = document.querySelectorAll('.slide-up');
var paragraphLinks = document.querySelectorAll('.body-text p a');

if (document.querySelector('.slide-up')) {
    var _loop = function _loop(i) {

        var el = appearingElements[i];
        var elementWatcher = scrollMonitor.create(el, -50);

        if (elementWatcher.isBelowViewport) {
            el.classList.add('below-vp');
        }

        elementWatcher.enterViewport(function () {
            el.classList.remove('below-vp');
        });
    };

    for (var i = 0; i < appearingElements.length; i++) {
        _loop(i);
    }
}

if (document.querySelector('.body-text p a')) {
    var _loop2 = function _loop2(i) {

        var el = paragraphLinks[i];
        var elementWatcher = scrollMonitor.create(el, -260);

        elementWatcher.enterViewport(function () {
            el.classList.add('in-vp');
        });
    };

    for (var i = 0; i < paragraphLinks.length; i++) {
        _loop2(i);
    }
}